import {useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Form} from "react-bootstrap";

export function Home()
{
    return <>
    <Header />
    <AboutMe />
    <Experience />
    <Projects />
    <Footer />
    </>
}

export function ContactMe()
{
    return <div className="contactMe">
        <ContactHeader />
        <div className="sendMessage">
            <p>Send me a message</p>
        </div>
        <SendEmail />
    </div>
}

function Header()
{
    function scrollToAbout(){
        var element = document.getElementsByClassName("aboutMe")[0];
        element.scrollIntoView();
    }
    function scrollToExperience(){
        var element = document.getElementsByClassName("blackBox")[0];
        element.scrollIntoView();
    }
    function scrollToProject(){
        var element = document.getElementsByClassName("whiteBox")[0];
        element.scrollIntoView();
    }
    return <div className="header">
        <Link className="navItem" href="/" onClick={scrollToAbout}>About</Link>
        <Link className="navItem" href="#experience" onClick={scrollToExperience}>Experience</Link>
        <Link className="navItem" href="#projects" onClick={scrollToProject}>Project</Link>
        <Link className="navItem" href="/apis">ME!!</Link>
        <Link className="navItem" to="/contactMe">Contact Me</Link>
    </div> 
}

function AboutMe()
{
    function scrollDown(){
        var element = document.getElementsByClassName("blackBox")[0];
        element.scrollIntoView();
    }
    return <div className="aboutMe" style={{ backgroundImage: "url(/static/images/background.jpg)" }}>
        <p className="aboutText name">M</p> 
        <p className="aboutText name">A</p>
        <p className="aboutText name">T</p>
        <p className="aboutText name">T</p>
        <p className="aboutText name">H</p>
        <p className="aboutText name">E</p>
        <p className="aboutText name">W</p>
        <p className="aboutText name"> </p>
        <p className="aboutText name">Y</p>
        <p className="aboutText name">A</p>
        <p className="aboutText name">N</p>
        <p className="aboutText name">G</p>
        <p className="aboutText name">.</p>
        <div className="scrollDown" onClick={scrollDown}>
            <h4 className="">Read More</h4>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-chevron-double-down" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            <path fill-rule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
    </div> 
}

function Experience()
{
    const [selectId, setSelectId] = useState(0);

    return <div className="blackBox">
        <div className="container">
            <p className="worked">Where I've Worked</p>
            <div className="exp">
                <div>
                    <ul className="workedList">
                        <li className={selectId == 0 ? 'experienceActive' : 'experience'} onClick={() => setSelectId(0)}>Oracle</li>
                        <li className={selectId == 1 ? 'experienceActive' : 'experience'} onClick={() => setSelectId(1)}>Gloria Stone</li>
                    </ul>
                </div>
                <div>
                    <div className={selectId == 0 ? 'descriptionActive' : 'description'}>
                        <h5 className="jobTitle">Software Developer Co-op </h5>
                        <h5 className="jobTitle2">@ Oracle Eloqua</h5>
                        <p className="jobDate">May 2021 - April 2022</p>
                        <ul className="bulletPoints">
                            <li className="bulletPoints">Write modern, performant, maintainable code for a diverse array of client and internal projects</li>
                            <li className="bulletPoints">Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, Gatsby, React, Craft, WordPress, Prismic, and Netlify</li>
                        </ul>
                    </div>
                    <div className={selectId == 1 ? 'descriptionActive' : 'description'}>
                        <h5 className="jobTitle">Intern Web Developer </h5>
                        <h5 className="jobTitle2">@ Glory Stone</h5>
                        <p className="jobDate">July 2020 - December 2020</p>
                        <ul className="bulletPoints">
                            <li className="bulletPoints">Write modern, performant, maintainable code for a diverse array of client and internal projects</li>
                            <li className="bulletPoints">Work with a variety of different languages, platforms, frameworks, and content management systems such as JavaScript, TypeScript, Gatsby, React, Craft, WordPress, Prismic, and Netlify</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

function Projects()
{
    return <div className="whiteBox">
        <p className="projectTitleWhite">Some Works I've done</p>
        <div className="container">
            <table className="projectTable">
                <tr className="heads">
                    <th>Title</th>
                    <th>Description</th>
                    <th>Language</th>
                    <th>Created at</th>
                </tr>
                <tr>
                    <td>One Music Playlist</td>
                    <td>Webapp that connects playlist from different platforms</td>
                    <td>Java</td>
                    <td>Sep 2021</td>
                </tr>
                <tr>
                    <td>E-commerce Platform</td>
                    <td>An ecommerce website created using React and Django</td>
                    <td>Python</td>
                    <td>Jul 2020</td>
                </tr>
                <tr>
                    <td>Adventure of POSt</td>
                    <td>Android app that contains 3 distinct games for users to enjoy </td>
                    <td>Java</td>
                    <td>Jan 2020</td>
                </tr>
            </table>
        </div>
    </div>
}

function Footer()
{
    return <div className="bottom">
        <p>STAY CONNECTED</p>
        <ul className="icons">
            <li>
                <a href="https://github.com/matticyang"  target="_blank">
                    <img className="bottomIcon" src="/static/images/github.png"></img>
                </a>
            </li>
            <li>
                <a>
                    <img className="bottomIcon" src="/static/images/email.png"></img>
                </a>
            </li>
        </ul>
    </div>
}

function ContactHeader(){
    return <div className="contactHeader">
        <Link className="contactNavItem" to="/">Home</Link>
        <Link className="contactNavItem" to="/contactMe">Contact Me</Link>
    </div> 
}

function SendEmail(){
    function submit(){
        
    }
    return <div className="container">
        <Form>
            <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter name" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" placeholder="Enter email" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicSubject">
                <Form.Label>Subject</Form.Label>
                <Form.Control type="text" placeholder="Enter subject" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicReason">
                <Form.Label>Reason</Form.Label>
                    {/* <Form.Select aria-label="Default select example">
                    <option>Just to say Hi</option>
                    <option value="1">Business</option>
                </Form.Select> */}
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" rows={3} />
        </Form.Group>
        </Form>
        <div className="submit">
            <Button variant="outline-secondary" onClick={submit}>Submit</Button>
        </div>
    </div>
}