import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { BrowserRouter as Router, Route, Link, Switch} from "react-router-dom";
import {Home, ContactMe} from './component/index';

function App() {
  return (
    <Router>
      <div className="page">
        <Switch>
          <Route path="/contactMe">
            <ContactMe />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          
        </Switch>
      </div>
    </Router>
  );
}

export default App;
